@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

#vrtx-frontpage {
  h1 {
    display: none;
  }
}

.grid-container.full-width-picture-bg {
  margin-top: -47px;
}

.grid-container.row-all-no-margin-bottom.full-width-picture-bg {
  margin-top: -47px;
  margin-bottom: 40px !important;
}

.not-for-ansatte {
  //search
  .header-search-collapsable .header-search-expand {
    .header-search-expand {
      button {
        background-color: @color-contrast;
        &:focus,
        &:hover {
          background-color: @color-contrast--light;
        }
      }
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-primary--air;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand,
    button {
      background-color: @color-contrast;
      &:focus,
      &:hover {
        background-color: @color-primary;
      }
    }
  }

  #head-wrapper {
    background-color: @color-light;
    height: 187px;
    #head {
      height: 187px;
      .head-menu {
        right: 110px;
        .language {
          display: none;
        }
        .vrtx-dropdown-link {
          background: transparent
            url("../images/language-dropdown-toggle-black.png")
            no-repeat scroll 100% 53%;
          margin-right: 7px;
        }
        & > .vrtx-login-manage-component > a,
        & > ul > li a {
          color: @color-neutral--dark;
        }
      }
      #header {
        height: 187px;
        a.head-logo {
          position: absolute;
          height: 187;
          padding-bottom: 20px;
          padding-top: 20px;
          color: @color-primary;
          img {
            width: 450px;
            &:hover,
            &:focus {
              color: @color-secondary !important;
              text-decoration: none;
            }
          }
        }
        ul > li a,
        .vrtx-login-manage-component > a {
          color: @color-neutral;
        }
      }
    }
    .vrtx-frontpage-box-picture img {
      margin-top: -36px;
    }
    .uio-app-name {
      display: none;
    }
  }
}

a.button.contrast {
  background-color: @color-contrast !important;
  &:hover,
  &:focus {
    background-color: @color-primary !important;
  }
}

a.button.primary {
  background-color: @color-primary !important;
  &:hover,
  &:focus {
    background-color: @color-contrast !important;
  }
}

table.vrtx-person-listing {
  td.vrtx-person-listing-name {
    .vrtx-image {
      img {
        border-radius: 50%;
        width: 120px;
        padding: 0;
      }
      padding-right: 14px;
    }
  }
}
#vrtx-person-contact-info-wrapper {
  .vrtx-person-image {
    border-radius: 50%;
    width: 120px;
    padding: 0;
  }
}
.round-image {
  img {
    border-radius: 50%;
    max-width: 200px;
    padding: 0;
  }
}

/*.vevent {
    border-top: 6px solid #e4e4e4;
    padding-top: 20px;
}*/
.vrtx-introduction {
  padding-bottom: 20px;
}

#main {
  margin-top: 100px;
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
  input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) {
    background-color: @color-primary;
    &:focus,
    &:hover {
      background-color: @color-neutral--light;
    }
  }
}

#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul {
    li.intranet {
      display: none;
    }
    li,
    li.vrtx-active-item {
      background: @color-primary;
      a {
        color: @color-light;
      }
      a:hover,
      a:focus {
        text-decoration: underline;
        color: @color-light !important;
        background-color: @color-contrast !important;
      }
    }
    li.vrtx-active-item a {
      background-color: @color-light !important;
      color: @color-primary--dark !important;
    }
  }
}

// leftmenu
#left-main {
  ul.vrtx-breadcrumb-menu {
    li a {
      background-color: @color-primary--air !important;
      &.vrtx-marked {
        background-color: @color-primary--light !important;
        border: none;
        color: @color-link;
      }
    }

    li.vrtx-child {
      > span.vrtx-after-marked,
      > a.vrtx-after-marked,
      > span.vrtx-marked,
      > a.vrtx-marked {
        border-top: none;
      }
    }

    li.vrtx-ancestor {
      > a:after,
      > span:after {
        border-color: @color-primary--air transparent transparent;
      }
    }
  }
}

.vrtx-frontpage-box-picture {
  z-index: -1;
}

.vrtx-frontpage-box.vrtx-header-false.vrtx-more-false.content-over-image-left
  .vrtx-box-content {
  margin-top: 40px !important;
}

table th {
  font-weight: bold;
}

body {
  .vrtx-facts-container {
    background-color: @color-contrast--light;
    &.primary {
      background-color: @color-primary--air;
    }
  }
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: rgba(0, 0, 0, 0) url("../images/arrow-forward-orange.svg")
    no-repeat scroll;
  background-size: 100% auto;
  background-position: 0 0;
}

blockquote {
  border-left: 4px solid @color-contrast !important;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @color-primary--air;
}

// Blogg-banner
.vrtx-frontpage-box-picture.blog {
  .vrtx-box-content {
    background-color: @color-light;
    margin-top: 120px;
    margin-left: -40px;
    padding: 20px 20px 20px 40px;
  }
  h2 {
    color: @color-neutral--dark;
    .font-size(60);
    font-weight: 200;
    line-height: normal;
  }
  p {
    color: @color-neutral;
    .font-size(22);
    font-weight: 700;
  }
}

//footer
.eu-flag > span {
  padding-top: 20px;
  float: left;
  padding-left: 10px;
}
.eu-flag > img {
  padding-top: 20px;
  float: left;
}

#footer-wrapper {
  margin-top: 40px;
  color: @color-neutral--dark;
  .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
    background: rgba(0, 0, 0, 0) url("../images/dropdown-black.png") no-repeat
      scroll right center / 22px auto;
  }
  h2 {
    color: @color-neutral--dark;
  }
  #footers {
    a {
      color: @color-link;
    }
    background: none;
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  #vrtx-frontpage #main {
    margin-top: 0 !important;
    .vrtx-frontpage-box {
      margin-bottom: 0;
    }
  }
  .not-for-ansatte {
    .grid-container.full-width-picture-bg {
      margin-bottom: 0 !important;
    }
    #offcanvas-wrapper > div {
      top: 48px;
    }
    #head-wrapper #head {
      height: auto;
      a.head-logo {
        background: @color-light url("../images/logo-responsive.png") no-repeat
          scroll;
        height: 150px;
        position: absolute !important;
        width: 100%;
        top: 14px;
        left: 20px;
        img {
          display: none;
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
    #lang-link a {
      display: none !important;
    }

    // Blogg-banner
    .vrtx-frontpage-box-picture.blog {
      .vrtx-box-content {
        margin-top: 0px;
        margin-left: 0px;
        padding: 0 0 15px 0;
      }
      h2 {
        .font-size(36);
        font-weight: 200;
        line-height: normal;
      }
      p {
        color: @color-neutral;
        .font-size(22);
        font-weight: 700;
      }
    }
  }
}
/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary: #008ec8;
@color-secondary: #8fb8df;
@color-primary--light: #c9daef;
@color-primary--air: #e9f0f9;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-contrast: #db8a4f;
@color-contrast--light: #eec6a6;
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #4f4f4f;
@color-neutral: #949494;
@color-neutral--light: #e4e4e4;
@color-lines: #f0f0f0;

// Suplementary colors
@color-light: #ffffff;
@color-dark: @color-neutral--dark;
